const str =
    `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*::before, *::after {
    box-sizing: border-box;
}

.skin {
    position: relative;
    background-color: #fbe74d;
    height: 100vh;
}
.face-area{
    position:absolute;
    top: -200px;
    width: 100%;
   ;
}
.nose {
    position: absolute;
    height: 0px;
    width: 0px;
    border: 1px solid pink;
    margin-top: 300px;
    left: 50%;
    margin-left: -10px;
    border: 10px solid black;
    border-color: black transparent transparent transparent;
}
.skin:hover .nose{
    animation: .3s linear infinite  wave;
}
.nose::before {
    position: absolute;
    content: '';
    display: block;
    border: 5px solid black;
    top: -16px;
    left: -10px;
    width: 20px;
    height: 6px;
    border-radius: 50%;
}

@keyframes wave {
    0% {

    }
    33.33% {
    transform: rotate(5deg);
    }
    66.66% {
        transform: rotate(-5deg);
    }
    100%{

    }
}

.eye {
    position: absolute;
    border: 1px solid black;
    height: 62px;
    width: 62px;
    margin-top: 269px;
    left: 50%;
    margin-left: -31px;
    border-radius: 50%;
    border-color: black;
    background-color: #2e2e2e;
}

.eye::before {
    position: absolute;
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 50%;
    border-color: black;
    left: 10px;
    top: 4px;
}

.eye.left {
    transform: translateX(-100px);
}

.eye.right {
    transform: translateX(100px);
}

.mouth {
    height: 50px;
    background-color: #fbe74d;
    /*border: 1px solid red;*/
    position: absolute;
    width: 230px;
    height: 200px;
    margin-top: 340px;
    left: 50%;
    margin-left: -115px;


}


.mouth .lip {
    position: absolute;
    top: -6px;
    border: 3px solid black;
    width: 80px;
    height: 20px;
    left: 50%;
    background-color: #fbe74d;
    z-index: 101;
}

.mouth .lip.left {
    margin-left: -80px;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 45px 20px;
    transform: rotate(-20deg);

}

.mouth .lip.right {
    border-top: none;
    border-left: none;
    border-bottom-right-radius: 45px 20px;
    transform: rotate(20deg);
}

.mouth .down {
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 100px 100px;
    border-top-right-radius: 100px 100px;

}

.mouth .down .red {
    position: absolute;
    left: 50%;
    bottom: 50px;
    bottom: 50px;
    margin-left: -70px;
    border: 3px solid black;
    width: 140px;
    height: 600px;
    border-radius: 2000%/2000%;
    overflow: hidden;
    background-color: #8e1b17;
    /*overflow: hidden;*/
}

.mouth .down .red .dark-red {
    z-index: 12;
    left: 50%;
    margin-left: -50px;
    bottom: -74px;
    position: absolute;

    height: 200px;
    width: 100px;
    background-color: #eb5763;
    border-top-left-radius: 80px 70px;
    border-top-right-radius: 80px 70px;
}

.face {
    width: 80px;
    height: 80px;
    border: solid 1px red;
    position: absolute;
    left: 50%;
    margin-left: -40px;
    top: 380px;
    border: 3px solid black;
    background-color: #ea3323;
    border-radius: 50%;
}

.face-left {
    transform: translateX(-160px);

}

.face-right {
    transform: translateX(160px);

}
`

export default str
